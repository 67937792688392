// Socials.js
import React from 'react';
import SocialX from './images/twitter.png';
import SocialInstagram from './images/instagram.png';
import SocialFace from './images/facebook.png';
import SocialLinkedin from './images/linkedin.png';
import './Socials.css';

// TODO: Maybe make put this in the footer
const Socials = () => {
  return (
    <div className="row social-media my-5">
      <div className="col col-12 px-5 d-flex justify-content-center text-center">
        <h4>Get in contact with me at <a href="https://alejandro-nunez.com">alejandro-nunez.com</a></h4> 
      </div>
      <div className="col col-12 px-5 d-flex justify-content-center">
        <p>Or Follow me in my social media</p>  
      </div>
      <div className="col col-12 px-5 d-flex justify-content-center">
        <a href="https://twitter.com/anunezfullstack" >
          <img src={SocialX} alt="X logo link" />
        </a>
        <a href="https://www.instagram.com/alejandro.nunez.fullstack/" > 
          <img src={SocialInstagram} alt="Instagram logo link" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61558045113623">
          <img src={SocialFace} alt="Facebook logo link" />
        </a>
        <a href="https://www.linkedin.com/in/nunez-alejandro/" >
          <img src={SocialLinkedin} alt="Linkedin logo link" />
        </a>
      </div>

    </div>
  );
}
export default Socials;

