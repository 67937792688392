// About.js
import React from 'react';
import Navbar from './Navbar.js';
import PhotoOfMe from './images/about_me2.png';
import Socials from './Socials.js';
import AboutMeProjectCards from './AboutMeProjectCards.js';
import './About.css';

const About = () => {
  return (
    <>
      <Navbar />
      {/* About me summary */}
      <div className="container">
        <div id="about-me-section" 
          className="row mt-5 mx-5 pt-5 justify-content-center about-me-section">
          <div className="col col-12 col-md-10 col-lg-9 col-xl-8">
            <div className="row">
              <div className="col order-2">
                <h1 className="text-center">About Alejandro Núñez</h1>
                <img src={PhotoOfMe} alt="Photo of me" />
                <p className="text-justify">I'm an electrical power engineer with master in optimal control of electrical power systems, a data engineer, a full stack ASP.NET web developper, a .NET software developper, a linux server administrator to name a few.</p>
              </div>
            </div>
          </div>
        </div>

        <AboutMeProjectCards />

        <Socials />
      </div>
    </>
  );
}
export default About;
