import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { dailyEnergyPerItem, monthlyEnergyPerItem, dailyCostPerItem, monthlyCostPerItem } from './energyPerItem.js';

export default function ItemList ({listItems, setlistItems, onItemDelete, energyCost }) {

  function removeItem (id) {
    var tempList = [...listItems.slice(0, id), ...listItems.slice(id + 1)];
    setlistItems(tempList);
    onItemDelete([...tempList].map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null));
  }

  return(
    <div>
      {listItems && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">kWh per month</th>
              <th scope="col">$USD per month</th>
            </tr>
          </thead>
          <tbody>
            {listItems.map(item => (
              item.name != null ?
                <tr key={Math.random()}>
                  <td>{item.name}</td>
                  <td>{monthlyEnergyPerItem(item)}</td>
                  <td>{monthlyCostPerItem(item, energyCost)}</td>
                  <td>
                    <a href="#" className="text-danger" onClick={() => removeItem(listItems.indexOf(item))}>X</a>
                  </td>
                </tr>
                : null
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
