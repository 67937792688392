import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

export default function EnergyCostForm ({ updateEnergyCost }) {
  const [formData, setFormData] = useState({
    energyCost: '0.14',
  });
  const [errors, setErrors] = useState ({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value

    });
    let tempErrors = {};

    if(!formData.energyCost.trim()) {
      tempErrors.energyCost = 'Field is required';
    }else if(!isFloat(formData.energyCost.trim())){ 
      tempErrors.energyCost = 'Only positive numbers';
    }

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0){
      updateEnergyCost(formData.energyCost);
    };

  };

  function isInteger(str) {
    // Regular expression to match an integer number
    const intRegex = /^\d+$/;
    return intRegex.test(str);
  }

  function isFloat(str) {
    // Regular expression to match a float number
    const floatRegex = /^\d*(\.\d+)?$/;
    return floatRegex.test(str);
  }

  return(
    <div className="row">
      <div className="col-12 mt-1">
        <label>Energy Cost ($/kWh)</label>
        <input type="number" 
          step="0.01"
          name="energyCost" 
          className="form-control" 
          placeholder="Enter energy cost" 
          value={formData.energyCost}
          onChange={handleChange} />
        {errors.energyCost && <span className="text-danger">{errors.energyCost}</span>}
      </div>
    </div>
  );
}
