// About.js
import React from 'react';
import Photo from './images/fish.jpg';
import './AboutMeProjectCards.css';

const AboutMeProjectCards = () => {
  return (
    <>
      <div 
        className="row mx-5 pt-5 justify-content-center about-me-projects">
        <div className="col col-12 col-md-10 col-lg-9 col-xl-8">
          <div className="row justify-content-center">
            <div className="col col-12 pb-3">
              <h1 className="text-center">Some of my Projects</h1>
            </div>

            <div className="project col mt-1 mx-2">
              <h2 className="text-center">Projects 1</h2>
              <div className="project-image" >
                <img src={Photo} alt="Photo" />
                {/*
                */}
              </div>
              <p className="text-justify">I'm an electrical power engineer with master in optimal control of electrical power systems, a data engineer, a full stack ASP.NET web developper, a .NET software developper, a linux server administrator to name a few.</p>
              <div className="text-center" >
                <a className="btn btn-primary" href="https://alejandro-nunez.com" >Learn more</a>
              </div>
            </div>

            <div className="project col mt-1 mx-2">
              <h2 className="text-center">Projects 2</h2>
              <div className="project-image" >
                <img src={Photo} alt="Photo" />
              </div>
              <p className="text-justify">I'm an electrical power engineer with master in optimal control of electrical power systems, a data engineer, a full stack ASP.NET web developper, a .NET software developper, a linux server administrator to name a few.</p>
              <div className="text-center" >
                <a className="btn btn-primary" href="https://alejandro-nunez.com" >Learn more</a>
              </div>
            </div>

            <div className="project col mt-1 mx-2">
              <h2 className="text-center">Projects 3</h2>
              <div className="project-image" >
                <img src={Photo} alt="Photo" />
              </div>
              <p className="text-justify">I'm an electrical power engineer with master in optimal control of electrical power systems, a data engineer, a full stack ASP.NET web developper, a .NET software developper, a linux server administrator to name a few.</p>
              <div className="text-center" >
                <a className="btn btn-primary" href="https://alejandro-nunez.com" >Learn more</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
export default AboutMeProjectCards;
