import './PowerCal.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

import Navbar from './Navbar.js';
import './Navbar.css';
import TotalEnergy from './TotalEnergy.js';
import ItemForm from './ItemForm.js';
import ItemList from './ItemList.js';
import { monthlyEnergyPerItem, dailyCostPerItem, monthlyCostPerItem } from './energyPerItem.js';
import EnergyCostForm from './EnergyCostForm.js';

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PowerCal () {
  const [listItems, setlistItems] = useState([Array(4).fill(null)]);
  const [energyCost, setEnergyCost] = useState(0.14);
  const [data, setData] = useState(null);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Dataset 1',
        data,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cost of energy per appliance',
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: '$USD'
        }
      }
    }
  });

  const updateChartData = (newItemList) => {
    const newData = {
      labels: newItemList.map(item => item != null ? item.name : null),
      datasets: [
        {
          data: newItemList.map(item => item != null ? monthlyCostPerItem(item, energyCost) : null),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };

    setChartData(newData);
  }

  const handleEnergyCostFormSubmit = (newEnergyCost) => {
    updateChartData([...listItems].map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null));
  };

  const handleFormSubmit = (formData) => {
    setlistItems([...listItems.map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null), formData]);
    updateChartData([...listItems, formData].map( item => (item != null && item.name != null) ? item : null).filter(name => name !== null));
  };

  const updateEnergyCost = (newEnergyCost) => {
    setEnergyCost(newEnergyCost);
    handleEnergyCostFormSubmit(newEnergyCost)
  }

  const getStarted = () => {
    document.getElementById('powercal-start-button').classList.add('hidden-powercal-start-button');
    document.getElementById('powercal-start-button').classList.remove('row');
    document.getElementById('powercal').classList.remove('hidden-powercal');
  }

  return(
    <>
      <Navbar />
      <div id="powercal-start-button" 
        className="row powercal-start-button">
        <div className="col col-12 justify-content-center mt-5 text-center">
          <h1 className="my-5">Welcome</h1>
          <p className="my-5">This is a tool for calculating your electrical energy consumption based on your home devices and usarge.</p>
          <button className="btn btn-primary" onClick={getStarted}>Let's get started</button>
        </div>
      </div>

      <div id="powercal" 
        className="row hidden-powercal justify-content-center powercal">
        <div className="col col-12">
          <h1 className="text-center my-5">Energy Calculator</h1>
        </div>
        <div className="col col-12 col-md-5 px-5 my-md-5 px-md-5">
          <div className="power-form px-3 mb-3">
            <p className="text-center pt-2 text-muted">Please enter your home appliances details</p>
            <ItemForm 
              onFormSubmit={handleFormSubmit}
            />
          </div>
        </div>

        <div className="col col-12 col-md-5 px-5 my-md-5 px-md-5">
          <div className="graph px-3">
            <div className="row justify-content-center">
            <div className="col col-12 col-md-4 text-center">
            <EnergyCostForm 
              updateEnergyCost={updateEnergyCost}
            />
            </div>
            </div>
            <Bar options={chartOptions} data={chartData} />

            <TotalEnergy listItems={listItems} energyCost={energyCost}/>
            <div className="overflow-scroll">
            <ItemList 
              listItems={listItems}
              setlistItems={setlistItems} 
              onItemDelete={updateChartData}
              energyCost={energyCost}
            />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
