// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PowerCal from './PowerCal';
import About from './About';
import NotFound from './NotFound';
import './default-bg.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PowerCal />} />
        <Route path="/About" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;

