import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import { monthlyEnergyPerItem } from './energyPerItem.js';
import './ItemForm.css';


export default function ItemForm ({ onFormSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    units: '',
    wattage: '',
    intensity: '',
    usage: '',
    days: '',
  });
  const [errors, setErrors] = useState ({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempErrors = {};
  {/* Checks if the name has only alphabet letters */}
    if(!formData.name.trim()) {
      tempErrors.name = 'Field is required';
    }else if(!/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(formData.name.trim())) {
      tempErrors.name = 'Only alphabet letters A-Z';
    }

    if(!formData.units.trim()) {
      tempErrors.units = 'Field is required';
    }else if(!isInteger(formData.units.trim())) {
      tempErrors.units = 'Only positive numbers intergers';
    }

    if(!formData.wattage.trim()) {
      tempErrors.wattage = 'Field is required';
    }else if(!isFloat(formData.wattage.trim())){ 
      tempErrors.wattage = 'Only positive numbers';
    }

    if(!formData.intensity.trim()) {
      tempErrors.intensity = 'Field is required';
    }else if(!isFloat(formData.intensity.trim())){ 
      tempErrors.intensity = 'Only positive numbers';
    }else if(parseFloat(formData.intensity) < 0 || 
      parseFloat(formData.intensity) > 100 ){
      tempErrors.intensity = 'Value must be from 0 to 100';
    }

    if(!formData.usage.trim()) {
      tempErrors.usage = 'Field is required';
    }else if(!isFloat(formData.usage.trim())){ 
      tempErrors.usage = 'Only positive numbers';
    }else if(parseFloat(formData.usage) < 0 || 
      parseFloat(formData.usage) > 24 ){
      tempErrors.usage = 'Value must be from 0 to 24';
    }

    if(!formData.days.trim()) {
      tempErrors.days = 'Field is required';
    }else if(!isFloat(formData.days.trim())){ 
      tempErrors.days = 'Only positive numbers';
    }else if(parseFloat(formData.days) < 0 || 
      parseFloat(formData.days) > 31 ){
      tempErrors.days = 'Value must be from 0 to 31';
    }

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0){
      onFormSubmit(formData);
    }
  }

function isInteger(str) {
  // Regular expression to match an integer number
  const intRegex = /^\d+$/;
  return intRegex.test(str);
}

  function isFloat(str) {
  // Regular expression to match a float number
  const floatRegex = /^\d*(\.\d+)?$/;
  return floatRegex.test(str);
}

  return(
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 mt-1">
            <label>Name</label>
            <input type="text" 
              name="name" 
              className="form-control" 
              placeholder="Enter appliance name" 
              value={formData.name}
              onChange={handleChange} />
            {errors.name && <span className="text-danger">{errors.name}</span>}
          </div>

          <div className="col-12 mt-1">
            <label>Units</label>
            <input type="text" 
              name="units" 
              className="form-control" 
              placeholder="Enter number of units" 
              value={formData.units}
              onChange={handleChange} />
            {errors.units && <span className="text-danger">{errors.units}</span>}
          </div>

          <div className="col-12 mt-1">
            <label>Wattage</label>
            <input type="text" 
              name="wattage" 
              className="form-control" 
              placeholder="Enter appliance wattage" 
              value={formData.wattage}
              onChange={handleChange} />        
            {errors.wattage && <span className="text-danger">{errors.wattage}</span>}
          </div>
          <div className="col-12 mt-1">
            <label>Intensity (0% to 100%)</label>
            <input type="text" 
              name="intensity" 
              className="form-control" 
              placeholder="Enter intensity" 
              value={formData.intensity}
              onChange={handleChange} />        
            {errors.intensity && <span className="text-danger">{errors.intensity}</span>}
          </div>
          <div className="col-12 mt-1">
            <label>Hours of usage per day</label>
            <input type="text" 
              name="usage" 
              className="form-control" 
              placeholder="Enter amount of hours" 
              value={formData.usage}
              onChange={handleChange} />        
            {errors.usage && <span className="text-danger">{errors.usage}</span>}
          </div>

          <div className="col-12 mt-1">
            <label>Days of usage per month</label>
            <input type="text" 
              name="days" 
              className="form-control" 
              placeholder="Enter amount of days" 
              value={formData.days}
              onChange={handleChange} />        
            {errors.days && <span className="text-danger">{errors.days}</span>}
          </div>

          <div className="text-center my-2">
            <button type="submit" 
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>      
        </div>
      </form>
    </div>
  );
}
